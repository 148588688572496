<template>
  <div :class="{'custom-table-row':true, 'custom-table-brief':!expandView && expandId !== row.id}" @click="$emit('row-click', row.id)">
    <div class="custom-table-line">
      <!--- 시간 --->
      <div class="custom-table-column-normal">{{transformTimestampToDate}}</div>
      <!--- 공장 --->
      <div class="custom-table-column-normal custom-table-column-sm-ignore" v-if="myAuthorizationLevel.level < 100">{{updated_data.branch_name}}</div>
      <!--- 거래처 --->
      <div class="custom-table-column-normal">
        <CSelect :disabled="!edit"
          class="p-2 custom-select_style"
          :options="CORRESPONDENT_OPTS || []"
          :value.sync="updated_data.correspondent_id"
          @change="onCorrespondent"
        >
        </CSelect>
      </div>
      <!--- 산지 --->
      <div class="custom-table-column-normal custom-table-column-sm-ignore">
        <CSelect :disabled="!edit || !updated_data.correspondent_id"
          class="p-2 custom-select_style"
          :options="ORIGIN_OPTS || []"
          :value.sync="updated_data.origin"
          @change="onUpdated"
        >
        </CSelect>
      </div>
      <!--- 종류 --->
      <div class="custom-table-column-long custom-table-column-sm-ignore">
        <CSelect :disabled="!edit"
          class="p-2 custom-select_style"
          :options="FREIGHT_OPTS || []"
          :value.sync="updated_data.freight_id"
          @change="onUpdated"
        >
        </CSelect>
      </div>
      <!--- 적재량 --->
      <div class="custom-table-column-normal custom-table-column-sm-ignore">
        <CSelect
          :disabled="!edit"
          class="p-2 custom-select_style"
          :options="quantityOpts"
          :value.sync="updated_data.quantity"
          @change="onUpdated"
        >
        </CSelect>
      </div>
      <!--- 차량번호 --->
      <div class="custom-table-column-normal custom-table-column-sm-ignore">
        <CInput :disabled="!edit" class="p-2 custom-select_style" v-model="updated_data.lp_num" @change="onUpdated"/>
      </div>
      <!--- 점검 --->
      <div class="custom-table-column-longlong">
        <el-radio-group v-model="updated_data.state" class="p-2 custom-select_style" size="small" :disabled="!edit" @change="onUpdated">
          <el-radio-button v-for="v in VALIDITY_OPTS" :label="v.value" :key="`validity-opts-${v.value}`">{{v.label}}</el-radio-button>
        </el-radio-group>
      </div>
      <!--- 부족량 --->
      <div class="custom-table-column-short custom-table-column-sm-ignore">
        <CInput v-if="updated_data.state === 0" :disabled="!edit" class="p-2 custom-select_style" v-model="updated_data.deficiency" type="number" :min="0" :max="20" :step="0.5" @input="onUpdated">
          <!-- <template #append-content>㎥</template> -->
        </CInput>
      </div>
    </div>

    <CCollapse :show="expandView || (expandId === row.id)">
      <CRow class="justify-content-start p-4">
        <div class="custom-image-container mr-2 mb-2" style="position:relative;" @click="onPlateImage">
          <CImg :src="row.image_1" class="w-100" v-if="expandView || (expandId === row.id)" />
          <div style="position:absolute;top:18px;left:9%;background-color:rgba(200,140,120,.8);" class="px-2 h4 font-weight-bold">{{row.lp_num}}</div>
        </div>
        <div v-if="row.metadata" class="custom-image-container mr-2 mb-2" @click="onImage(row.metadata.selected_image)">
          <CImg :src="row[`image_${row.metadata.selected_image+2}`]" class="w-100" v-if="expandView || (expandId === row.id)" />
        </div>
        <div v-else-if="row.image_2" class="custom-image-container mr-2 mb-2" @click="onImage(0)">
          <CImg :src="row.image_2" class="w-100" v-if="expandView || (expandId === row.id)" />
        </div>
        <!-- <div class="custom-image-container mr-2 mb-2" @click="onImage(1)" v-if="row.image_2">
          <CImg :src="row.image_2" class="w-100" v-if="expandView || (expandId === row.id)" />
        </div>
        <div class="custom-image-container mr-2 mb-2" @click="onImage(2)" v-if="row.image_3">
          <CImg :src="row.image_3" class="w-100" v-if="expandView || (expandId === row.id)" />
        </div>
        <div class="custom-image-container mr-2 mb-2" @click="onImage(3)" v-if="row.image_4">
          <CImg :src="row.image_4" class="w-100" v-if="expandView || (expandId === row.id)" />
        </div> -->
      </CRow>
    </CCollapse>
  </div>
</template>

<script>
import utility from '@/utility.js'
import { mapGetters } from 'vuex'

export default {
  name: 'DeliveryListItem',

  props: {
    row: {type: Object, default: null},
    expandView: {type:Boolean, default:true},
    expandId: {type:Number, default:0},
    edit: {type:Boolean, default:false},
    CORRESPONDENT_OPTS: {type: Array, default: () => {return [];}},
    FREIGHT_OPTS: {type: Array, default: () => {return [];}}
  },
  data() {
    return {
      force_expand: false,
      updated_data: {},
      VALIDITY_OPTS: [
        {value: 2, label: "미검수"},
        {value: 1, label: "정상"},
        {value: 0, label: "불량"}
      ],
      ORIGIN_OPTS: []
    }
  },
  mounted() {
    this.updated_data = JSON.parse(JSON.stringify(this.row));
    let correspondent = this.CORRESPONDENT_OPTS.find(el => el.value === this.updated_data.correspondent_id);
    let origins = correspondent.origins;
    if (!origins) {
      this.ORIGIN_OPTS = [{
        value: null, label: '정보 없음'
      }];
      return;
    }
    this.ORIGIN_OPTS = origins.map(el => {
      return {value:el.name, label:el.name};
    });
    this.ORIGIN_OPTS.push({
      value: null, label: '정보 없음'
    });
  },
  computed: {
    ...mapGetters([
      'myAuthorizationLevel'
    ]),
    transformTimestampToDate(){
      return utility.GetDateTimeStr('$yyyy-$mm-$dd $HH:$MM:$ss', new Date(this.row.created_at));
    },
    validity(){
      if (this.updated_data.state === 1) return '정상';
      else if (this.updated_data.state === 0) return '불량';
      else return '미검수';
    },
    quantityOpts() {
      const rtn = [];
      if (this.updated_data.correspondent_id) {
        let correspondent = this.CORRESPONDENT_OPTS.find(el => el.value === this.updated_data.correspondent_id);
        for (const val of correspondent.quantity_contract) {
          rtn.push({value:val, label:`${val}㎥`});
        }
      }
      rtn.push({value: null, label: '정보 없음'});
      return rtn;
    }
  },
  methods: {
    onCorrespondent() {
      let correspondent = this.CORRESPONDENT_OPTS.find(el => el.value === this.updated_data.correspondent_id);
      let origins = correspondent.origins;
      if (!origins) {
        this.updated_data.origin = null;
        this.ORIGIN_OPTS = [{
          value: null, label: '정보 없음'
        }]
        this.onUpdated();
        return;
      }
      this.ORIGIN_OPTS = origins.map(el => {
        return {value:el.name, label:el.name};
      });
      this.ORIGIN_OPTS.push({
        value: null, label: '정보 없음'
      });
      let match = this.ORIGIN_OPTS.find(el => el.value === this.updated_data.origin);
      if (!match) {
        this.updated_data.origin = this.ORIGIN_OPTS[0].value;
      }
      this.onUpdated();
    },
    onUpdated(){
      if (this.updated_data.state !== 0) {
        this.updated_data.deficiency = 0;
      }
      console.log(this.updated_data);
      this.$emit('updated', this.updated_data);
    },
    onImage(index){
      if (this.edit) {
        // const urls = [
        //   this.row.image_1
        // ];
        if (!this.row.image_2) return;
        const urls = [
          this.row.image_2
        ];
        if (this.row.image_3) urls.push(this.row.image_3);
        if (this.row.image_4) urls.push(this.row.image_4);
        this.$emit('image-click', {
          id: this.row.id,
          urls, index
        });
      }
    },
    onPlateImage() {
      if (this.edit) {
        const urls = [
          this.row.image_1
        ];
        this.$emit('plate-image-click', {
          id: this.row.id,
          urls,
          index: 0,
          lp_num: this.row.lp_num
        });
      }
    }
  }
}
</script>
